import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { ENV_CONFIG } from 'config/environment';
import { defaultOptions, getJSONFile, LottieProps } from './commonOptions';
import * as animationBlueOrbs from '../../assets/json/blueOrbs.json';
import { BlurredBackground, ChildrenContainer } from './styled';

const getIsCanvasBlurred = (slide?: string) => {
  return !!slide && slide !== 'intro';
};

const LottieStory: FC<LottieProps> = ({ children, slide, isPaused, style, childrenStyle }) => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Fetch the JSON file from the URL
    if (slide) {
      const fetchAnimationData = async () => {
        try {
          const response = await fetch(
            `${ENV_CONFIG().CLOUD_STORAGE.URL}/json/${getJSONFile(slide)}.json`
          );
          const jsonData = await response.json();
          setAnimationData(jsonData);
        } catch (error) {
          setAnimationData(animationBlueOrbs);
          console.error('Error loading JSON:', error);
        }
      };

      fetchAnimationData();
    }
  }, [slide]);

  return (
    <>
      {getIsCanvasBlurred(slide) && animationData && <BlurredBackground />}
      {animationData && (
        <Lottie
          options={defaultOptions(animationData)}
          isClickToPauseDisabled={true}
          isPaused={isPaused}
          style={style}
        />
      )}
      <ChildrenContainer style={childrenStyle}>{children}</ChildrenContainer>
    </>
  );
};

export default LottieStory;
