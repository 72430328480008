import { FC, useEffect, useState } from 'react';
import { animate, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import LottieStory from 'components/LottieElements/Story';
import { formatNumberAsCurrency } from 'helpers/utils';
import { DURATION_1, DURATION_2, FADE_OUT_LEFT, OPACITY_0, OPACITY_1 } from './animations';
import { ISlideProps } from '.';
import {
  BigCounter,
  Container,
  GiveSmarterText,
  MotionContainer,
  MotionText,
  OuterContainer,
  SquareContainer,
} from './styled';
import { Eyebrow } from 'components/Typography';

export const Slide1: FC<ISlideProps> = ({ isPaused, impactReportData }) => {
  const [showFirstLayer, setShowFirstLayer] = useState(true);
  const [showSecondLayer, setShowSecondLayer] = useState(false);
  const [showThirdLayer, setShowThirdLayer] = useState(false);
  const [showLastLayer, setShowLastLayer] = useState(false);

  const amountDonated = useMotionValue(0);
  const amountDonatedCount = useTransform(amountDonated, latest =>
    formatNumberAsCurrency(Math.round(latest))
  );
  const amountWorth = useMotionValue(0);
  const amountWorthCount = useTransform(amountWorth, latest =>
    formatNumberAsCurrency(Math.round(latest))
  );

  useEffect(() => {
    if (showFirstLayer) {
      setTimeout(() => {
        setShowFirstLayer(false);
        setShowSecondLayer(true);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (showSecondLayer) {
      const controls = animate(amountDonated, parseFloat(impactReportData.donation_amount_total), {
        duration: 3,
      });
      if (isPaused) controls.pause();
    }
  }, [showSecondLayer, isPaused]);

  useEffect(() => {
    if (showThirdLayer) {
      const controls = animate(
        amountWorth,
        parseFloat(impactReportData.donation_amount_total_adjusted),
        {
          duration: 3,
        }
      );
      if (isPaused) controls.pause();
    }
  }, [showThirdLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = amountDonated.on('change', latest => {
      if (Math.round(latest) >= Math.round(parseFloat(impactReportData.donation_amount_total))) {
        setTimeout(() => {
          setShowSecondLayer(false);
          setShowThirdLayer(true);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountDonated]);

  useEffect(() => {
    const unsubscribe = amountWorth.on('change', latest => {
      if (
        Math.round(latest) >=
        Math.round(parseFloat(impactReportData.donation_amount_total_adjusted))
      ) {
        setTimeout(() => {
          setShowThirdLayer(false);
          setShowLastLayer(true);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountWorth]);

  return (
    <>
      <GiveSmarterText>GIVE SMARTER @OATH.VOTE</GiveSmarterText>
      {(showFirstLayer || showSecondLayer) && (
        <LottieStory slide="blueOrbs" isPaused={isPaused}>
          <AnimatePresence>
            {showFirstLayer && (
              <Container
                key="firstLayer"
                initial={OPACITY_0}
                animate={OPACITY_1}
                exit={FADE_OUT_LEFT}
                transition={DURATION_2}
              >
                <MotionText>
                  Throughout the
                  <br />
                  2024 election cycle,
                </MotionText>
              </Container>
            )}
          </AnimatePresence>
        </LottieStory>
      )}
      {!showFirstLayer && (
        <AnimatePresence>
          <MotionContainer
            key="secondLayerContainer"
            initial={{ ...OPACITY_0, backgroundColor: theme.colors.transparent }}
            animate={{ ...OPACITY_1, backgroundColor: theme.colors.inkBlue }}
            exit={OPACITY_1}
            transition={DURATION_1}
          >
            <AnimatePresence>
              {showSecondLayer && (
                <Container
                  key="secondLayer"
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  exit={OPACITY_1}
                  transition={DURATION_2}
                  style={{ zIndex: 1 }}
                >
                  <BigCounter
                    exit={OPACITY_0}
                    transition={{ duration: 0 }}
                    $size={
                      parseInt(impactReportData.donation_amount_total).toString().length === 5
                        ? 95
                        : parseInt(impactReportData.donation_amount_total).toString().length > 5
                        ? 80
                        : 120
                    }
                  >
                    {amountDonatedCount}
                  </BigCounter>
                  <Spacing $size={24} />
                  <MotionText exit={OPACITY_0}>
                    You donated{' '}
                    {formatNumberAsCurrency(
                      Math.round(parseFloat(impactReportData.donation_amount_total))
                    )}{' '}
                    on Oath
                  </MotionText>
                </Container>
              )}
              {showThirdLayer && (
                <Container
                  key="secondThirdLayer"
                  initial={OPACITY_1}
                  animate={{ y: -180 }}
                  transition={DURATION_1}
                  $alignContent="space-around"
                >
                  <BigCounter
                    $size={
                      parseInt(impactReportData.donation_amount_total).toString().length === 5
                        ? 95
                        : parseInt(impactReportData.donation_amount_total).toString().length > 5
                        ? 80
                        : 120
                    }
                  >
                    {amountDonatedCount.get()}
                  </BigCounter>
                </Container>
              )}
              {showLastLayer && (
                <>
                  <Container
                    key="secondLastLayer"
                    initial={{ y: '50%', backgroundColor: theme.colors.oathBlue }}
                    animate={{ y: '0%', backgroundColor: theme.colors.oathBlue }}
                    transition={DURATION_2}
                    $alignContent="space-around"
                  />
                  <OuterContainer animate={{ paddingTop: 20 }} transition={DURATION_2}>
                    <SquareContainer>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={
                          parseInt(impactReportData.donation_amount_total).toString().length === 5
                            ? 85
                            : parseInt(impactReportData.donation_amount_total).toString().length ===
                              6
                            ? 67
                            : parseInt(impactReportData.donation_amount_total).toString().length > 6
                            ? 60
                            : 95
                        }
                      >
                        {amountDonatedCount.get()}
                      </BigCounter>
                      <Spacing $size={4} />
                      <Eyebrow $color={theme.colors.white}>IN DONATIONS</Eyebrow>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={80}
                      >
                        =
                      </BigCounter>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={
                          parseInt(impactReportData.donation_amount_total_adjusted).toString()
                            .length === 5
                            ? 85
                            : parseInt(impactReportData.donation_amount_total_adjusted).toString()
                                .length > 5
                            ? 67
                            : parseInt(impactReportData.donation_amount_total_adjusted).toString()
                                .length > 6
                            ? 60
                            : 95
                        }
                      >
                        {amountWorthCount.get()}
                      </BigCounter>
                      <Spacing $size={4} />
                      <Eyebrow $color={theme.colors.white}>OF IMPACT</Eyebrow>
                    </SquareContainer>
                  </OuterContainer>
                </>
              )}
            </AnimatePresence>
          </MotionContainer>
        </AnimatePresence>
      )}
      {showThirdLayer && (
        <AnimatePresence>
          <MotionContainer
            key="ThirdLayerContainer"
            initial={{ y: '100%', backgroundColor: theme.colors.transparent }}
            animate={{ y: '50%', backgroundColor: theme.colors.oathBlue }}
            transition={DURATION_1}
          >
            {showThirdLayer && (
              <>
                <MotionText
                  initial={{
                    ...OPACITY_0,
                    position: 'absolute',
                    top: -16,
                    width: '100%',
                    textAlign: 'center',
                  }}
                  animate={OPACITY_1}
                  exit={OPACITY_0}
                  transition={DURATION_1}
                >
                  Your {amountDonatedCount.get()} donation was worth
                </MotionText>
                <Container
                  key="thirdLayer"
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  exit={OPACITY_1}
                  transition={DURATION_1}
                  $alignContent="space-around"
                >
                  <BigCounter
                    exit={{ ...OPACITY_1, y: -window.innerHeight / 3 }}
                    transition={DURATION_1}
                    $size={
                      parseInt(impactReportData.donation_amount_total_adjusted).toString()
                        .length === 5
                        ? 95
                        : parseInt(impactReportData.donation_amount_total_adjusted).toString()
                            .length > 5
                        ? 80
                        : 115
                    }
                  >
                    {amountWorthCount}
                  </BigCounter>
                  <BigCounter
                    exit={OPACITY_0}
                    $size={
                      parseInt(impactReportData.donation_amount_total_adjusted).toString()
                        .length === 5
                        ? 95
                        : parseInt(impactReportData.donation_amount_total_adjusted).toString()
                            .length > 5
                        ? 80
                        : 115
                    }
                  >
                    {amountDonatedCount.get()}
                  </BigCounter>
                </Container>
              </>
            )}
          </MotionContainer>
        </AnimatePresence>
      )}
      <AnimatePresence>
        {showLastLayer && (
          <Container
            key="lastLayer"
            initial={{ ...OPACITY_1, y: '50%', backgroundColor: theme.colors.oathBlue }}
            animate={OPACITY_1}
            transition={DURATION_1}
            $alignContent="flex-start"
            style={{ padding: '0 30px' }}
            $useDefaultsForFirefox={true}
          >
            <Spacing $size={12} />
            <MotionText $size={20}>
              Research shows that donations have the most impact: early in the cycle, downballot,
              and in close races.
            </MotionText>
            <Spacing $size={12} />
            <MotionText $size={20}>
              By giving smarter with Oath, you had{' '}
              <MotionText $size={26}>{impactReportData.donation_amount_multiplier}x</MotionText> the
              impact of an average donor!
            </MotionText>
          </Container>
        )}
      </AnimatePresence>
    </>
  );
};
