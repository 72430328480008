import styled from 'styled-components/macro';

export const OrbsContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 38%;
  height: -webkit-fill-available;
  right: 0;
  top: 36px;

  @media (max-width: 1000px) {
    position: relative;
    width: 100%;
    max-height: 200px;
  }
`;

export const ImpactOrbsContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 38%;
  height: 100%;
  right: 0;
  top: 0;

  @media (max-width: 1000px) {
    min-height: auto;
    position: relative;
    width: 100%;
  }
`;

export const ChildrenContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  z-index: 2;

  @media (max-width: 1000px) {
    top: 16px;
  }
`;

export const ChildrenContainerProfileSection = styled.div`
  padding: 16px;
  position: relative;
  z-index: 5;

  @media (min-width: 1000px) {
    padding-top: 60px;
  }
`;

export const BlurredBackground = styled.div`
  backdrop-filter: blur(50px);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  -webkit-backdrop-filter: blur(50px);
`;

export const BlurredBackgroundProfileCard = styled(BlurredBackground)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  @media (min-width: 1000px) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }
`;
