import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

const CTA = ({ children, ...props }) => {
  if (props.href) {
    return <a {...props}>{children}</a>;
  }
  if (props.to) {
    const { to, ...rest } = props;
    return (
      <RouterLink to={to} {...rest}>
        {children}
      </RouterLink>
    );
  }
  return <button {...props}>{children}</button>;
};

export const ButtonBase = styled(CTA)`
  border: none;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
`;

export const ButtonBrand = styled(CTA)<{
  $secondary?: boolean;
  $align?: string;
  $isProcessing?: boolean;
  $hasBorder?: boolean;
  $backgroundColor?: string;
  $width?: string;
  $withShadow?: boolean;
}>`
  background: ${({ $backgroundColor, $secondary, theme }) =>
    $backgroundColor || ($secondary ? theme.colors.white : theme.colors.oathBlue)};
  border: none;
  outline: ${({ $hasBorder, theme }) =>
    $hasBorder ? `1px solid ${theme.colors.inkBlue} !important` : 'none'};
  text-align: ${({ $align }) => $align || 'center'};
  border-radius: 100px;
  color: ${({ $secondary, theme }) => ($secondary ? theme.colors.inkBlue : theme.colors.white)};
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 14px;
  outline: inherit;
  padding: 11px 20px;
  text-decoration: none;
  text-transform: uppercase;
  align-content: center;
  width: ${({ $width }) => $width || 'auto'};
  ${({ theme }) => theme.fonts.semiBold};
  ${({ $withShadow }) => $withShadow && 'box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.25)'};
  &:hover {
    background: ${({ theme, $backgroundColor }) =>
      `linear-gradient(270deg, ${$backgroundColor || theme.colors.oathBlue} 0%, ${
        theme.colors.electricBlue
      } 100%)`};
    color: ${({ theme }) => theme.colors.white};
    outline: none !important;
  }
  ${({ $isProcessing, theme }) =>
    $isProcessing &&
    `
    background: linear-gradient(270deg, ${theme.colors.oathBlue} 0%, ${theme.colors.electricBlue} 100%);
    color: ${theme.colors.white};
    pointer-events: none;
    `}
  &[disabled] {
    background: ${({ theme }) => theme.colors.alto};
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background: ${({ theme }) => theme.colors.alto};
    }
  }
`;
