import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import { Image } from 'components/Image';
import { H3 } from 'components/Typography';
import styled from 'styled-components/macro';

export const Title = styled.h1`
  font-size: 60px;
  ${({ theme }) => theme.fonts.light};
  letter-spacing: -0.01em;
  line-height: 65px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 650px;

  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 43px;
  }
`;

export const Subtitle = styled.h2<{ $panelFont?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  max-width: 650px;
  font-size: 40px;
  letter-spacing: -0.01em;
  margin: 0;
  line-height: 44px;
  ${({ $panelFont, theme }) => ($panelFont ? theme.fonts.panelSansMedium : theme.fonts.light)};

  @media (max-width: 1000px) {
    font-size: 30px;
    line-height: 33px;
  }
`;

export const Label = styled(H3)`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.panelSansMedium};
`;

export const Content = styled(Column)<{ $centered?: boolean }>`
  max-width: 650px;
  padding: 0 20px;
  ${({ $centered }) =>
    $centered &&
    `
    text-align: center;
    justify-content: center;
    margin: auto;
  `}
`;

export const TopContainer = styled(Column)`
  background-size: cover;
  background-image: url(${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/header.png);
  padding: 280px 110px 64px 110px;

  @media (max-width: 1000px) {
    padding: 280px 20px 64px;
  }
`;

export const Container = styled(Column)`
  background: ${({ theme }) => theme.colors.inkBlue};
  padding: 80px 0;

  @media (max-width: 1000px) {
    padding: 60px 0;
  }
`;

export const BottomContainer = styled(Column)`
  background: ${({ theme }) =>
    `linear-gradient(100.04deg, ${theme.colors.oathBlue} 10.51%, ${theme.colors.inkBlue} 47.67%)`};
  padding: 150px 110px;

  @media (max-width: 1000px) {
    padding: 80px 20px;
  }
`;

export const RowContent = styled(Column)<{ $fitSize?: boolean }>`
  padding: 40px 80px;

  @media (max-width: 1000px) {
    padding: ${({ $fitSize }) => ($fitSize ? 0 : '80px 20px')};
  }
`;

export const StyledRow = styled(Row)<{
  $isReverse?: boolean;
  $fitSize?: boolean;
}>`
  ${({ $fitSize }) => $fitSize && 'min-width: max-content'};
  gap: 16px;

  @media (max-width: 1000px) {
    flex-direction: ${({ $isReverse }) => ($isReverse ? 'column-reverse' : 'column')};
  }
  a {
    flex-grow: 1;
  }
`;

export const StyledImage = styled(Image)<{ $end?: boolean; $hideOnMobile?: boolean }>`
  ${({ $hideOnMobile }) => !$hideOnMobile && 'width: 100%'};
  max-width: 526px;

  @media (max-width: 1000px) {
    ${({ $hideOnMobile }) => $hideOnMobile && 'display: none'};
  }
  @media (max-width: 800px) {
    width: 85%;
    align-self: ${({ $end }) => ($end ? 'flex-end' : 'flex-start')};
  }
`;

export const CircledImage = styled(Image)`
  height: 45px;
  width: 45px;
  border-radius: 100px;
`;
