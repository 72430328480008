import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import { Row, RowToGrid } from 'components/Row';
import Column from 'components/Column';
import ButtonLink from 'components/ButtonLink';
import { Body, BodySmall, H2Text, H3 } from 'components/Typography';
import { Link } from 'components/Link';
import { ButtonBrand } from 'components/ButtonBrand';

export const Container = styled(Column)<{ $isFixed: boolean }>`
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.oathBlue} 0%, ${theme.colors.inkBlue} 100%)`};
  width: 100%;
  height: 100%;
  padding: 40px 0;

  @media (max-width: 1000px) {
    ${({ $isFixed }) =>
      $isFixed &&
      `
        position: fixed;
        z-index: 11;
        top: 0;
        height: auto;
      `};
  }
`;

const Content = styled(Column)`
  @media (max-width: 1000px) {
    width: auto !important;
  }
`;

export const RowContainer = styled(RowToGrid)<{ $isMixed?: boolean; $minHeight?: string }>`
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight};`}
  ${({ $isMixed }) =>
    $isMixed &&
    `
        @media (min-width: 1000px) and (max-width: 1400px) {
          display: grid;
        }
        @media (max-width: 1000px) {
          display: flex;
        }
      `}
`;

export const MixedSpacing = styled.div`
  width: 16px;
  height: 100%;

  @media (max-width: 1000px) {
    width: 100%;
    height: 16px;
  }
`;

export const CardsContainer = styled(RowToGrid)`
  padding: 32px;
`;

export const LeftContent = styled(Content)<{ $withBorder?: boolean; $needsPadding?: boolean }>`
  padding: ${({ $needsPadding }) => ($needsPadding ? '16px' : '0')};

  @media (min-width: 1000px) and (max-width: 1450px) {
    padding-bottom: ${({ $needsPadding }) => ($needsPadding ? '80px' : '0')};
  }

  ${({ $withBorder, theme }) =>
    $withBorder &&
    `
      border-right: 1px solid ${theme.shadows.disabled(0.5)};
      padding-right: 16px;

      @media (max-width: 1000px) {
        border-right: none;
        padding-right: 0px;
        border-bottom: 1px solid ${theme.shadows.disabled(0.5)};
        padding-bottom: 16px;
      }
    `}
`;

export const RightContent = styled(Content)<{ $withBorder?: boolean }>`
  ${({ $withBorder, theme }) =>
    $withBorder &&
    `
      border-left: 1px solid ${theme.shadows.disabled(0.5)};
      padding-left: 16px;

      @media (max-width: 1000px) {
        border-left: none;
        padding-left: 0px;
        border-top: 1px solid ${theme.shadows.disabled(0.5)};
        padding-top: 16px;
      }
    `}
`;

export const HeaderTitle = styled(H2Text)`
  color: ${({ theme }) => theme.colors.white};
  padding: 0 32px;
`;

export const Navbar = styled(Row)`
  padding: 20px 32px 0;
  gap: 10px;

  @media (min-width: 1000px) {
    width: calc(66% - 64px);
    padding-top: 60px;
  }
`;

export const NavbarItem = styled(Body)`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
`;

export const NavbarItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const NavbarUnderline = styled.div<{ $isSelected: boolean }>`
  background: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.electricBlue : 'none')};
  border-radius: 6px;
  height: 3px;
  margin-top: 6px;
  width: 100%;
`;

export const SmallText = styled(BodySmall)`
  color: ${({ theme, color }) => color || theme.colors.shaft};
`;

export const BigText = styled(H3)<{ color?: string }>`
  text-transform: capitalize;
  color: ${({ theme, color }) => color || theme.colors.inkBlue};
`;

export const Issue = styled(motion.div)<{ $isFinal?: boolean }>`
  padding: 4px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.shadows.inkBlue(0.15)};
  width: fit-content;

  ${({ $isFinal, theme }) =>
    $isFinal &&
    `
    background: ${theme.colors.platinum};
    margin-bottom: 0;
    cursor: pointer;
    `}
`;

export const IssueEdit = styled(Issue)<{ $isSelected?: boolean }>`
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 8px;
  ${({ $isSelected, theme }) =>
    !$isSelected &&
    `
    background: ${theme.colors.platinum};
    `}
  &:hover {
    background: ${({ theme }) => theme.colors.electricBlue};
  }
`;

export const SettingBlock = styled(Column)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

export const DonationHistoryShowMoreButton = styled(ButtonLink)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 11px;
  line-height: 16px;
  margin-top: 22px;
  text-align: left;
`;

export const Bubble = styled.div<{ $size: number }>`
  background: ${({ theme }) => theme.colors.platinum};
  height: ${({ $size }) => $size * 5}px;
  width: ${({ $size }) => $size * 5}px;
  border-radius: ${({ $size }) => $size * 5}px;
`;

export const ReferralCard = styled(Column)`
  background: ${({ theme }) =>
    `linear-gradient(to left, ${theme.colors.electricBlue}, ${theme.colors.darkGreen})`};
  padding: 20px 24px;
  border-radius: 10px;
  text-align: center;
`;

export const RecurringFooterRow = styled(Row)`
  gap: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 6px;
  }
`;

export const RecurringFooterText = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const RecurringCancelLink = styled(Link)`
  margin-left: 10px;
`;

export const RecurringCancelled = styled(BodySmall)`
  margin-left: 10px;
`;

export const CancelModalContent = styled.div`
  text-align: center;
`;

export const RecurringAmountMobileContainer = styled(Row)`
  flex-direction: column;
  height: 100%;
`;

export const SubmitButton = styled(ButtonBrand)<{ $submitting?: boolean }>`
  &[disabled] {
    ${({ theme }) =>
      `background: linear-gradient(to right, ${theme.colors.electricBlue}, ${theme.colors.darkGreen}, ${theme.colors.oathBlue})`};
    pointer-events: none;
  }
`;
