import { FC, useEffect, useState } from 'react';
import { animate, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { BodyBig } from 'components/Typography';
import LottieStory from 'components/LottieElements/Story';
import { formatNumber } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { getUserData } from 'selectors/user';
import { ISlideProps } from '.';
import { DURATION_1, DURATION_2, OPACITY_0, OPACITY_1 } from './animations';
import {
  BigCounter,
  BigCounterPlain,
  Container,
  GiveSmarterText,
  MotionContainer,
  MotionText,
  StyledLogo,
  SummarySquareContainer,
  Title,
} from './styled';

const MAX_SPAM_AVOIDED_COUNT = 5000;

export const Slide4: FC<ISlideProps> = ({ isPaused, impactReportData }) => {
  const userData = useAppSelector(getUserData);
  const [showFirstLayer, setShowFirstLayer] = useState(true);
  const [showSecondLayer, setShowSecondLayer] = useState(false);
  const spamAvoidedCount = Math.min(impactReportData.spam_avoided_count, MAX_SPAM_AVOIDED_COUNT);
  const showSpamAvoidedCountPlus = impactReportData.spam_avoided_count > MAX_SPAM_AVOIDED_COUNT;

  const amountSpam = useMotionValue(0);
  const amountSpamCount = useTransform(amountSpam, latest => formatNumber(Math.round(latest)));

  useEffect(() => {
    if (showFirstLayer) {
      const controls = animate(amountSpam, spamAvoidedCount, {
        duration: 3,
      });
      if (isPaused) controls.pause();
    }
  }, [showFirstLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = amountSpam.on('change', latest => {
      if (Math.round(latest) >= spamAvoidedCount && !isPaused) {
        setTimeout(() => {
          setShowFirstLayer(false);
          setShowSecondLayer(true);
        }, 5000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountSpam, isPaused]);

  return (
    <>
      <GiveSmarterText>GIVE SMARTER @OATH.VOTE</GiveSmarterText>
      {(showFirstLayer || showSecondLayer) && (
        <LottieStory slide="blueOrbs" isPaused={isPaused}>
          <AnimatePresence>
            {showFirstLayer && (
              <Container
                key="firstLayer"
                initial={OPACITY_0}
                animate={OPACITY_1}
                transition={DURATION_1}
                style={{ padding: '0 30px' }}
              >
                <span>
                  <BigCounter
                    $size={
                      spamAvoidedCount.toString().length === 5 || showSpamAvoidedCountPlus
                        ? 95
                        : spamAvoidedCount.toString().length > 5
                        ? 80
                        : 120
                    }
                  >
                    {amountSpamCount}
                  </BigCounter>
                  {showSpamAvoidedCountPlus && <BigCounterPlain $size={95}>+</BigCounterPlain>}
                </span>
                <Spacing $size={24} />
                <MotionText
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 2 }}
                >
                  Through Oath, you avoided
                </MotionText>
                <MotionText
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 2 }}
                >
                  {formatNumber(spamAvoidedCount)}
                  {showSpamAvoidedCountPlus && '+'} spam texts.
                </MotionText>
              </Container>
            )}
          </AnimatePresence>
        </LottieStory>
      )}
      {showSecondLayer && (
        <AnimatePresence>
          <MotionContainer
            key="secondLayerContainer"
            initial={{ y: '100%', backgroundColor: theme.colors.transparent, zIndex: 2 }}
            animate={{ y: '0%', backgroundColor: theme.colors.inkBlue }}
            transition={DURATION_1}
          >
            <AnimatePresence>
              {showSecondLayer && (
                <Container
                  key="secondLayer"
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  exit={OPACITY_0}
                  transition={{ ...DURATION_2, delay: 1 }}
                  $alignContent="flex-start"
                  $finalLayer
                  style={{ padding: '40px 30px 0' }}
                >
                  <SummarySquareContainer>
                    <LottieStory
                      slide="blueOrbs"
                      childrenStyle={{
                        top: 80,
                        justifyContent: 'center',
                      }}
                    >
                      <Title $size={38}>{userData.first_name}’s</Title>
                      <Spacing $size={4} />
                      <Title $size={38}>2024 Impact</Title>
                      <Spacing $size={4} />
                      <Title $size={38}>Report</Title>
                      <Spacing $size={60} />
                      <Row $alignItems="center">
                        <MotionText $size={16}>Powered by </MotionText>
                        <Spacing $isVertical $size={8} />
                        <StyledLogo
                          alt="Oath logo"
                          src={`${
                            ENV_CONFIG().CLOUD_STORAGE.URL
                          }/static_content/oath_logos/Oath_FullLockup_Color_LightText.svg`}
                        />
                      </Row>
                    </LottieStory>
                  </SummarySquareContainer>
                  <Spacing $size={30} />
                  <Row $width="100%" $justifyContent="space-between" $alignItems="flex-start">
                    <Column $width="49%">
                      <MotionText $size={14}>Candidates Supported</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {impactReportData.recipient_count}
                      </BodyBig>
                    </Column>
                    <Column $width="49%">
                      <MotionText $size={14}>Top Issue</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {impactReportData.issues?.length
                          ? impactReportData.issues[0].tag_name
                          : '-'}
                      </BodyBig>
                    </Column>
                  </Row>
                  <Spacing $size={16} />
                  <Row $width="100%" $justifyContent="space-between" $alignItems="flex-start">
                    <Column $width="49%">
                      <MotionText $size={14}>Candidates Elected</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {impactReportData.election_margin_min
                          ? impactReportData.recipient_ids_winners.length
                          : '-'}
                      </BodyBig>
                    </Column>
                    <Column $width="49%">
                      <MotionText $size={14}>My Impact</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {impactReportData.donation_amount_multiplier}x
                      </BodyBig>
                    </Column>
                  </Row>
                  <Spacing $size={16} />
                  <Row $width="100%" $justifyContent="space-between" $alignItems="flex-start">
                    <Column $width="49%">
                      <MotionText $size={14}>Average Impact Score</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {parseFloat(impactReportData.recipient_impact_score_average).toFixed(1)}/10
                      </BodyBig>
                    </Column>
                    <Column $width="49%">
                      <MotionText $size={14}>Spam Texts Avoided</MotionText>
                      <BodyBig $color={theme.colors.electricBlue}>
                        {formatNumber(spamAvoidedCount)}
                        {showSpamAvoidedCountPlus && '+'}
                      </BodyBig>
                    </Column>
                  </Row>
                </Container>
              )}
            </AnimatePresence>
          </MotionContainer>
        </AnimatePresence>
      )}
    </>
  );
};
