import theme from 'config/theme';
import Column from 'components/Column';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Body, Eyebrow, UtilityText } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { ModalComponent } from '.';

export const UnsavedModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      closeModal={onClose}
      style={{
        width: '-webkit-fill-available',
        height: 'fit-content',
        maxWidth: 350,
        border: 'none',
        margin: '20px auto',
        justifySelf: 'center',
      }}
      contentLabel="Unsaved changes modal"
      iconColor={theme.colors.black}
    >
      <Column $textAlign="center">
        <Eyebrow $color={theme.colors.inkBlue}>YOU HAVE UNSAVED CHANGES</Eyebrow>
        <Spacing $size={8} />
        <Body>Would you like to save your changes before going back?</Body>
        <Spacing $size={16} />
        <ButtonBrand onClick={() => onSubmit(true)} $backgroundColor={theme.colors.inkBlue}>
          Yes, save my changes
        </ButtonBrand>
        <Spacing $size={8} />
        <Link onClick={() => onSubmit(false)} $textAlign="center" $color={theme.colors.shaft}>
          <UtilityText $color={theme.colors.shaft}>No, continue without saving</UtilityText>
        </Link>
        <Spacing $size={8} />
      </Column>
    </ModalComponent>
  );
};
