import { useEffect, useState } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { RowToGrid } from 'components/Row';
import { Body } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { getPathWithRef } from 'helpers/utils';
import { ModalComponent } from '.';
import { StyledImage, TextContainer, Title } from './styled';

export const PausedModal = ({ paused_misc }) => {
  const [modalMaxWidth, setModalMaxWidth] = useState(650);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setModalMaxWidth(350);
      } else {
        setModalMaxWidth(650);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ModalComponent
      isOpen
      style={{
        width: '-webkit-fill-available',
        height: 'fit-content',
        maxWidth: modalMaxWidth,
        border: 'none',
        margin: '20px auto',
        justifySelf: 'center',
      }}
      contentLabel="Modal paused"
      iconColor={theme.colors.black}
    >
      <RowToGrid $switchToGrid={800}>
        <StyledImage
          alt="blue alert"
          src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/paused_donations.png`}
        />
        <TextContainer $verticalPadding="40px" $marginLeft="36%">
          <Title>Donations are Paused</Title>
          <Spacing $size={8} />
          <Body>{paused_misc?.paused_description}</Body>
          <Spacing $size={40} />
          <ButtonBrand
            to={getPathWithRef(paused_misc?.paused_redirect_url)}
            target="_blank"
            $backgroundColor={theme.colors.oathBlue}
          >
            {paused_misc?.paused_redirect_button}
          </ButtonBrand>
        </TextContainer>
      </RowToGrid>
    </ModalComponent>
  );
};
