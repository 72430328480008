import { ENV_CONFIG } from 'config/environment';
import LottieStory from 'components/LottieElements/Story';
import { Container, MotionText, StyledImage, Title } from './styled';

export const Intro = ({ isPaused }) => {
  return (
    <LottieStory slide="blueOrbs" isPaused={isPaused}>
      <Container>
        <Title>2024</Title>
        <MotionText $size={24}>Impact Report</MotionText>
        <StyledImage
          alt="Oath logo"
          src={`${
            ENV_CONFIG().CLOUD_STORAGE.URL
          }/static_content/oath_logos/Oath_FullLockup_Color_LightText.svg`}
        />
      </Container>
    </LottieStory>
  );
};
